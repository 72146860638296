import { graphql, useStaticQuery } from "gatsby"

export const usePostList = () => {
  const { allSitePage } = useStaticQuery(
    graphql`
      query POST_LIST_QUERY {
        allSitePage(
          filter: { context: { frontmatter: { published: { eq: true } } } }
          sort: { order: DESC, fields: context___frontmatter___date }
        ) {
          nodes {
            id
            path
            context {
              frontmatter {
                date
                title
                published
                image
                tldr
              }
            }
          }
        }
      }
    `
  )
  return allSitePage.nodes
}
