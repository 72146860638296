import React from "react"

import { usePostList } from "../hooks/usePostList"
import { Container } from "@material-ui/core"

import PostCard from "./posts/postCard"

const PostListing = () => {
  const posts = usePostList()

  return (
    <Container maxWidth="md">
      {posts.map(node => (
        <PostCard {...node} key={`post-${node.id}`} />
      ))}
    </Container>
  )
}

export default PostListing
