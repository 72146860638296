import React from "react";
import { Card, Typography, CardActionArea, CardMedia, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(3),
    },
    media: {
        height: 140,
        maxHeight: 200,
    },
    title: {
        textAlign: "center",
    },
  }));

const PostCard = (post) => {

    const title = post.context.frontmatter.title;
    const image = post.context.frontmatter.image ? `/posts/${post.context.frontmatter.image}` : "";//require(`../../images/posts/${post.context.frontmatter.image}`) : null;
    const tldr = post.context.frontmatter.tldr ? post.context.frontmatter.tldr : null;
    const postUrl = post.path ? post.path : "";
    const classes = useStyles();

    console.log(image);
    return (
      <Card className={classes.root}>
        <CardActionArea href={postUrl}>
          { image ? <CardMedia className={classes.media}
            image={image}
            title={title}
          /> : null}
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
                {tldr}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
}

export default PostCard;